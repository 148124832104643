    <h2 class="header">Create a New Change Request</h2>


  <div class="content">
        <button  mat-fab extended color="primary" class="load-draft-button" [disabled]="!selectedRow" (click)="loaddraft()">Load Draft</button>
        <h3>Choose a requst template</h3>
        <mat-table [dataSource]="templates" class="mat-elevation-z8">
          <ng-container matColumnDef="template">
            <mat-header-cell *matHeaderCellDef> Template </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"  (click)="navigateRow(row)" [class.selected-row]="selectedRow === row"></mat-row>
        </mat-table>
      </div>

