import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, Observable, of, tap} from "rxjs";
import {Template} from "../model/Template";
import {TemplatsRepo} from "../repo/templats-repo";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  private templatesSubject = new BehaviorSubject<Template[]>([]);
  templates$: Observable<Template[]> = this.templatesSubject.asObservable();
  constructor(private templatesRepo: TemplatsRepo) { }


  getTemplates(): void {
    this.templatesRepo.getTemplates().pipe(
      tap((data) => {
       data.data.forEach(item => {
          if (!["FW Regel erstellen", "FW Regel ändern", "FW Regel löschen"].includes(item.name)) {
            item.enabled = false;
          }else {
            item.enabled = true;
          }
       });
        this.templatesSubject.next(data.data);
      }),
      catchError((error) => {
        console.error('Error fetching devices:', error);
        return of([]);
      })
    ).subscribe();
  }
}
