import {Component, OnInit} from '@angular/core';
import {Template} from "../model/Template";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {TemplatesService} from "../store/templates.service";





@Component({
  selector: 'app-auswahl-templates',
  templateUrl: './auswahl-templates.component.html',
  styleUrl: './auswahl-templates.component.scss'
})
export class AuswahlTemplatesComponent implements OnInit{
  displayedColumns: string[] = ['template', 'description'];
  templates :Template[] = [];


  selectedRow: Template | null = null;
  constructor(private templatsStore: TemplatesService, private router: Router) {
    this.templatsStore.getTemplates()
  }


  ngOnInit(): void {
    this.templatsStore.templates$.subscribe(date =>{
      this.templates= date.filter(template => template.enabled)
    })
  }

  loaddraft(){
    if (this.selectedRow) {
      this.navigateRow(this.selectedRow)
    }
  }

  selectRow(row: Template){
    this.selectedRow = row;
  }

  navigateRow(row: Template) {
    switch (row.name) {
      case 'FW Regel erstellen':
        this.router.navigate([environment.routes.createRule]);
        break;
      case 'FW Regel löschen':
        this.router.navigate([environment.routes.deleteRule]);
        break;
      case 'FW Regel ändern':
        this.router.navigate([environment.routes.changeRule]);
        break;
      default:
        break;
    }
  }
}
