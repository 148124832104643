import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {TemplateResponse} from "../model/Template";

@Injectable({
  providedIn: 'root'
})
export class TemplatsRepo {

  constructor(private http: HttpClient) { }


  getTemplates(): Observable<TemplateResponse> {
    return this.http.get<TemplateResponse>(environment.api.baseUrl + '/getTemplates', {withCredentials:true})
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Ein Fehler ist aufgetreten: ${error.error.message}`;
    } else {
      errorMessage = `Server hat einen Fehlercode zurückgegeben: ${error.status}, ` + `Fehlerdetails: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
