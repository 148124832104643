import { NgModule } from '@angular/core';
import {SharedModule} from "../Shared/shared.module";
import {AuswahlTemplatesComponent} from "./auswahl-templates.component";
import {AuswahlTemplatesRoutingModule} from "./auswahl-templates.routing.module";



@NgModule({
  declarations: [
    AuswahlTemplatesComponent,
  ],
  imports: [
    AuswahlTemplatesRoutingModule,
    SharedModule,
  ]
})
export class AuswahlTemplatesModule {}
